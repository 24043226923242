<template>
  <div style="max-width: 1200px; margin: auto">
    <v-card v-if="!loadingItem" class="main-card">
      <v-row>
        <v-col cols="12" sm="12" md="5">
          <div class="item-image">
            <img :src="itemImagesUrl + selectedImage" style="width: 100%; height: 100%; object-fit: contain">
            <div v-if="item.discount > 0" class="ribbon ribbon-top-left">
              <span v-if="locale === 'en'">DISC. -{{ item.discount }}%</span>
              <span v-else-if="locale === 'de'">AKTION -{{ item.discount }}%</span>
              <span v-else>AKCIJA -{{ item.discount }}%</span>
            </div>
          </div>
          <div v-if="item.images.length > 1" class="image-selection">
            <img
                v-for="image in item.images" :key="image"
                :src="itemImagesUrl + image"
                :class="image===selectedImage ? 'selected' : ''"
                style="width: 23%; object-fit: contain; margin-right: 2%;aspect-ratio : 1 / 1;"
                @click="selectedImage = image"
            >
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="7">
          <v-card class="options-card">
            <h2 v-if="locale === 'en'">{{ item.nameEN }}</h2>
            <h2 v-else-if="locale === 'de'">{{ item.nameDE }}</h2>
            <h2 v-else>{{ item.nameSI }}</h2>
            <hr style="margin-top: 1px; margin-bottom: 20px">
            <div>
              <div>
                <h3 style="margin-bottom: 10px">{{ getSellPriceText() }}:
                  <v-chip :color="'#c7c7c7'" class="ml-5" v-if="item.discount === 0"><b style="font-size: 19px">{{ formatPrice(selectedOptionPrice) }} €</b></v-chip>
                  <v-chip :color="'#c7c7c7'" class="ml-5"  v-else>
                    <i style="font-size: 14px; text-decoration: line-through red">
                      {{ formatPrice(selectedOptionPrice) }} €
                    </i>
                    <b style="font-size: 19px; margin-left: 5px">
                      {{ applyDiscount(selectedOptionPrice, item.discount) }} €
                    </b>
                  </v-chip>
                </h3>
                <i style="font-size: 15px;">(</i>
                <i v-if="item.discount > 0" style="font-size: 12px; text-decoration: line-through red">{{ selectedOptionPrice.toFixed(2) }} {{ getUnit(item.type) }}</i>
                <i v-if="item.discount > 0" style="font-size: 15px; margin-left: 4px">{{ applyDiscountWithoutFormatting(selectedOptionPrice, item.discount).toFixed(2) }} {{ getUnit(item.type) }})</i>
                <i v-if="item.discount === 0" style="font-size: 15px; margin-left: 4px">{{ selectedOptionPrice.toFixed(2) }} {{ getUnit(item.type) }})</i>
                <br>
                <br>

                <p v-if="locale === 'en'" style="margin-bottom: 0">NET: {{ getNET(selectedOptionPrice, item.discount) }} €</p>
                <p v-else-if="locale === 'de'" style="margin-bottom: 0">Netto: {{ getNET(selectedOptionPrice, item.discount) }} €</p>
                <p v-else style="margin-bottom: 0">NETO: {{ getNET(selectedOptionPrice, item.discount) }} €</p>

                <p v-if="locale === 'en'">VAT: {{ getVAT(selectedOptionPrice, item.discount) }} €</p>
                <p v-else-if="locale === 'de'">MwSt: {{ getVAT(selectedOptionPrice, item.discount) }} €</p>
                <p v-else>DDV: {{ getVAT(selectedOptionPrice, item.discount) }} €</p>

                <v-select
                  v-if="item.options.length > 1"
                  v-model="selectedOptionPrice"
                  :items="item.options"
                  item-text="description"
                  item-value="price"
                  outlined
                  style="width: 300px; margin-top: 30px"
                >
                </v-select>

                <p v-if="item.type === 'FABRIC'" style="margin-bottom: 0; color: #b92929">
                  <i v-if="locale === 'en'">* Choose the length in meters with an accuracy of 0.1 m</i>
                  <i v-else-if="locale === 'de'">* Wählen Sie die Länge in Metern mit einer Genauigkeit von 0,1 m</i>
                  <i v-else>* Dolžino izberite v metrih, z natančnostjo 0.1m</i>
                </p>
                <p v-if="item.type === 'FABRIC'" style="color: #b92929;">
                  <i v-if="locale === 'en'">** Minimum length: 0.5 m</i>
                  <i v-else-if="locale === 'de'">** Mindestlänge: 0,5 m</i>
                  <i v-else>** Minimalna dolžina: 0.5m</i>
                </p>
                <p v-if="item.type === 'BLINDS'" style="color: #b92929;margin-bottom: 25px">
                  <i v-if="locale === 'en'">Accurately measure the width and height of the window (GLASS MEASUREMENT) and subtract 1mm from the width.
                    We do not accept any complaints due to incorrect measurements!</i>
                  <i v-else-if="locale === 'de'">Messen Sie genau die Breite und Höhe des Fensters (GLASMESSUNG) und ziehen Sie 1 mm von der Breite ab.
                    Wir akzeptieren keine Reklamationen wegen falsch angegebener Maße!</i>
                  <i v-else>Točno izmerite širino in višino okna (MERA STEKLA), ter od širine odbijte 1mm.
                    Reklamacij za napačno podane mere ne sprejemamo!</i>
                </p>

                <v-text-field
                    v-if="item.type === 'FABRIC' || item.type === 'FINISHED_PRODUCT'"
                    v-model="quantity"
                    type="number"
                    outlined
                    :label="getQuantityText()"
                    style="width: 100px"
                ></v-text-field>

                <v-row v-if="item.type === 'BLINDS'">
                  <v-col>
                    <v-text-field
                        v-model="width"
                        type="number"
                        outlined
                        :label="getWidthText()"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-model="height"
                        type="number"
                        outlined
                        :label="getHeightText()"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                        v-model="side"
                        outlined
                        :label="getSideText()"
                        :items="getLeftRightOptions()"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-btn
                    style="width: 100%"
                    color="primary"
                    @click="addToCart()"
                >
                  <span v-if="locale === 'en'">Add to basket</span>
                  <span v-else-if="locale === 'de'">In den Warenkorb</span>
                  <span v-else>Dodaj v košarico</span>
                </v-btn>
              </div>

            </div>


          </v-card>
        </v-col>
      </v-row>
      <v-card v-if="locale === 'en'" class="description-card">
        <h4>Description</h4><br>
        {{ item.descriptionEN }}
      </v-card>
      <v-card v-else-if="locale === 'de'" class="description-card">
        <h4>Bezeichnung</h4><br>
        {{ item.descriptionDE }}
      </v-card>
      <v-card v-else class="description-card">
        <h4>Opis</h4><br>
        <p style="white-space: break-spaces;">{{ item.descriptionSI }}</p>
      </v-card>
    </v-card>
    <v-card v-else style="margin-top: 30px">
      <div class="text-center" style="padding-top: 100px; padding-bottom: 80px">
        <v-progress-circular
            :size="80"
            :width="5"
            color="#9FBB38"
            indeterminate
        ></v-progress-circular>
      </div>
    </v-card>

    <v-dialog v-model="addToCartDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 mb-6" style="background-color: #48bd4a">
          <span style="color: white">Izdelek dodan v košarico</span>
        </v-card-title>

        <v-card-text>
          Izdelek ste uspešno dodali v košaico!<br><br>
          <small class="text-muted">Vašo košarico si lahko ogledate s pritiskom na gumb "košarica". Tam lahko naročilo oddate ali urejate izdelke v košarici.</small>
        </v-card-text>

        <v-card-actions style="padding: 20px">
          <v-spacer></v-spacer>
          <v-btn
              small
              color="success"
              @click="addToCartDialog = false"
          >
            Nadaljuj z nakupovanjem
          </v-btn>
          <v-btn
              color="primary"
              small
              @click="$router.push('/cart')"
          >
            Košarica
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import ItemsService from "@/services/items.service"
import EndpointsService from "@/services/endpoints.service"
import CartService from "@/services/cart.service";

export default {
  name: "item",
  title: "ZAVESE.EU",
  data() {
    return {
      itemId: "",

      loadingItem: false,
      itemError: false,
      item: {},

      selectedImage: "",
      selectedOptionPrice: 0,
      quantity: 1,
      width: 1000,
      height: 1500,
      side: "left",

      addToCartDialog: false,

      snackbar: false,
      snackbarColor: "#FFB300",
      displayMessage: "",
      locale:"",
    };
  },
  computed: {
    itemImagesUrl() {
      return EndpointsService.getApiV1Endpoint() + 'items/image/';
    }
  },
  mounted(){
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    if( urlParams.has('id') ){
      this.itemId = urlParams.get('id');
    }
    else
      this.$router.push("/home")

    this.getItem();
    if(localStorage.getItem("zavese-locale") === null){
      localStorage.setItem("zavese-locale", "si")
    }
    this.locale = localStorage.getItem("zavese-locale")
  },
  methods: {
    getItem(){
      this.loadingItem = true;
      this.itemError = false;
      ItemsService.getOne(this.itemId).then(
        response => {
          this.item = response.data
          this.loadingItem = false;
          this.selectedImage = this.item.defaultImage
          this.selectedOptionPrice = this.item.options[0].price
        },
        error => {
          this.itemError = true;
          this.loadingItem = false;
          console.log("ERROR GETTING ITEM:")
          console.log(error.response.data)
        }
      )
    },

    getPriceRange(item){
      if(item.options.length !== 0){
        var minPrice = 1000000000;
        var maxPrice = 0;
        for (var i = 0; i<item.options.length; i++){
          if(item.options[i].price > maxPrice)
            maxPrice = item.options[i].price
          if(item.options[i].price < minPrice)
            minPrice = item.options[i].price
        }
        return this.formatPrice(minPrice) + this.getUnit(item.type) + " - " + this.formatPrice(maxPrice) + this.getUnit(item.type)
      }
      else return "Error 021";
    },

    getUnit(type){
      if(type === "BLINDS")
        return "€/m²"
      else if (type === "FABRIC")
        return "€/m"
      else
        return "€"
    },

    formatPrice(number){
      if(this.item.type === "BLINDS"){
        let area = this.width * this.height
        if(area <= 1000000)
          return number.toFixed(2)
        else{
          let multiplier = area / 1000000;
          let price = number * multiplier
          return price.toFixed(2)
        }
      }
      else {
        let price = number * this.quantity
        return price.toFixed(2)
      }
    },

    applyDiscount(price, discount){
      return this.formatPrice(parseFloat(price) * (1.0 - (parseFloat(discount) / 100.0)))
    },

    applyDiscountWithoutFormatting(price, discount){
      return parseFloat(price) * (1.0 - (parseFloat(discount) / 100.0))
    },

    getVAT(price, discount){
      let value = parseFloat(price) * (1.0 - (parseFloat(discount) / 100.0));
      return this.formatPrice(value * 0.22)
    },

    getNET(price, discount){
      let value = parseFloat(price) * (1.0 - (parseFloat(discount) / 100.0));
      return this.formatPrice(value * 0.78)
    },

    getSellPriceText(){
      if(this.locale === "en") return "Sell price"
      else if(this.locale === "de") return "Brutto-Verkaufspreis"
      else return "Prodajna cena"
    },

    getWidthText(){
      if(this.locale === "en") return "Width [mm]"
      else if(this.locale === "de") return "Breite [mm]"
      else return "Širina [mm]"
    },

    getHeightText(){
      if(this.locale === "en") return "Height [mm]"
      else if(this.locale === "de") return "Höhe [mm]"
      else return "Višina [mm]"
    },

    getSideText(){
      if(this.locale === "en") return "Side or regulation stick"
      else if(this.locale === "de") return "Seite des Regelknüppels"
      else return "Stran palčke za uravnavanje"
    },

    getLeftRightOptions(){
      if(this.locale === "en") return [{ text: "LEFT", value: "left" }, { text: "RIGHT", value: "right" }]
      else if(this.locale === "de") return [{ text: "LINKS", value: "left" }, { text: "RECHTS", value: "right" }]
      else return [{ text: "LEVO", value: "left" }, { text: "DESNO", value: "right" }]
    },

    getQuantityText(){
      if(this.item.type === "FINISHED_PRODUCT"){
        if(this.locale === "en") return "Quantity"
        else if(this.locale === "de") return "Menge"
        else return "Količna"
      }
      else if(this.item.type === "FABRIC"){
        if(this.locale === "en") return "Length [m]"
        else if(this.locale === "de") return "Länge [m]"
        else return "Dolžina [m]"
      }
    },



    addToCart(){
      var option;
      for (var i = 0; i<this.item.options.length; i++){
        if(this.item.options[i].price === this.selectedOptionPrice)
          option = this.item.options[i];
      }
      if(this.item.type === "BLINDS")
        CartService.addBlindsToLocalCart(this.item, this.width, this.height, this.side, option);
      else if (this.item.type === "FABRIC")
        CartService.addFabricToLocalCart(this.item, this.quantity, option);
      else
        CartService.addToLocalCart(this.item, this.quantity, option);
      if(this.locale === "en") this.displayMessage = "Item successfully added to your cart!"
      else if(this.locale === "else") this.displayMessage = "Artikel erfolgreich zu Ihrem Warenkorb hinzugefügt!"
      else this.displayMessage = "Izdelek uspešno dodan v vašo košarico!"
      this.snackbar = true
      this.addToCartDialog = true
    }

  }
}
</script>

<style scoped>

@import "../css/ribbon.css";

  .main-card {
    padding: 30px;
    padding-bottom: 60px;
    margin: 15px;
    margin-top: 25px;
    background-color: #f3f3f3;
    max-width: 1800px;
  }
  .item-image {
    border: solid 1px #929292;
    border-radius: 10px;
    margin: auto;
    width: 100%;
    aspect-ratio : 1 / 1;
    margin-top: 20px;
  }
  .image-selection {
    margin-top:20px;
    padding: 2%;
    background-color: #e8e8e8;
    border-radius: 8px;
  }
  .selected {
    border: solid 2px #5c5c5c;
    border-radius: 5px;
  }
  .description-card {
    margin-top: 30px;
    padding: 20px;
    background-color: #e8e8e8;
  }

  .options-card {
    padding: 20px;
    margin-top: 20px;
    background-color: #e8e8e8;
  }

  @media only screen and (max-width: 950px){
    .main-card {
      padding: 5px;
    }
  }
</style>
